import { Component, computed, DestroyRef, effect, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { catchError, finalize, of, switchMap, tap } from 'rxjs';

import { SnackBar } from '@components/snack-bar';
import { StaticNotificationComponent } from '@components/static-notification';
import { FormValidationErrorsComponent } from '@components/form-fields';
import { SpinnerComponent } from '@components/spinner';
import { AuthService } from '@features/auth';
import { TicketOptions, emailValidator } from '@features/common';
import { ErrorsService } from '@features/errors';

import { BookingsService } from '../../services';
import { Booking, BookingTicket } from '../../models';

@Component({
  selector: 'app-assign-tickets',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    SpinnerComponent,
    StaticNotificationComponent,
    FormValidationErrorsComponent,
  ],
  templateUrl: './assign-tickets.component.html',
  styleUrl: './assign-tickets.component.scss',
})
export class AssignTicketsComponent implements OnInit {
  #fb = inject(FormBuilder);
  #auth = inject(AuthService);
  #bookingsService = inject(BookingsService);
  #destroyRef = inject(DestroyRef);
  #snackbar = inject(SnackBar);
  #errorService = inject(ErrorsService);

  booking = input.required<Booking | null>();
  allTickets = computed(() => this.booking()?.basket.tickets ?? []);

  protected busyTickets = signal<string[]>([]);
  protected myEmail = computed(() => this.#auth.user()?.email);
  protected form: FormGroup;
  protected assigningToMe = signal<boolean>(false);

  get tickets() {
    return this.form.get('tickets') as FormArray;
  }

  constructor() {
    this.form = this.#fb.group({ tickets: this.#fb.array([]) });
    effect(() => {
      console.log('booking', this.booking());
    });
  }

  ngOnInit() {
    for (const ticket of this.booking()?.basket.tickets ?? []) {
      ticket.isAssigned = !!(ticket.ticket_holder_email && ticket.ticket_holder_name);
      this.addTicket(ticket);
    }
  }

  getTicketHolder(ticketId: string) {
    return this.allTickets().find((ticket) => ticket.id === ticketId);
  }

  assignTicket(index: number, ticketId: string, name: string, email: string) {
    const bookingId = this.booking()?.id;
    this.ticketsBusy(ticketId);

    if (bookingId && ticketId) {
      this.#bookingsService
        .assignTicket(bookingId, ticketId, name, email)
        .pipe(
          tap(() => {
            const ticket = this.tickets.at(index) as FormGroup;
            if (ticket) {
              ticket.get('isAssigned')?.patchValue(true);

              ticket.patchValue({ ticket_holder_name: '', ticket_holder_email: '' }, { emitEvent: false });
              ticket.markAsUntouched();
              ticket.markAsPristine();
              ticket.updateValueAndValidity();

              this.#snackbar.success(`Ticket ${index + 1} has been assigned to ${name}`);
            }
          }),
          finalize(() => {
            const ticketHolder = this.getTicketHolder(ticketId);
            if (ticketHolder) {
              ticketHolder.ticket_holder_name = name;
              ticketHolder.ticket_holder_email = email;
            }
            return this.ticketsIdle(ticketId);
          }),
          catchError((error: { message: string; errors: { [key: string]: string[] } }) => {
            this.#snackbar.error(`An error occurred assigning the ticket. ${error.message}`);
            this.handleServerErrors(error.errors, index);
            return of(false);
          }),
        )
        .subscribe();
    }
  }

  assignToMe(index: number) {
    const bookingId = this.booking()?.id;
    const oldTicketId = this.tickets.controls
      .find((ticket) => ticket.get('ticket_holder_email')?.value === this.#auth.user()?.email)
      ?.get('id')?.value;
    const newTicketId = this.tickets.controls[index].get('id')?.value;

    if (bookingId && newTicketId) {
      this.assigningToMe.set(true);
      this.ticketsBusy(oldTicketId, newTicketId);

      const unassignTicket = oldTicketId
        ? this.#bookingsService
            .unassignTicket(bookingId, oldTicketId)
            .pipe(tap(() => this.clearTicketAssignment(oldTicketId)))
        : of({});

      unassignTicket
        .pipe(
          switchMap(() => {
            return this.#bookingsService
              .assignTicket(bookingId, newTicketId, this.#auth.user()?.name ?? '', this.#auth.user()?.email ?? '')
              .pipe(
                tap(() => {
                  this.markTicketAssigned(newTicketId);
                  const ticket = this.tickets.at(index) as FormGroup;
                  if (ticket) {
                    ticket.markAsUntouched();
                    ticket.markAsPristine();
                    ticket.updateValueAndValidity();
                  }
                  this.#snackbar.success(`Ticket ${index + 1} has been assigned to you`);
                }),
              );
          }),
          takeUntilDestroyed(this.#destroyRef),
          finalize(() => {
            this.assigningToMe.set(false);
            const ticketHolder = this.getTicketHolder(newTicketId);
            if (ticketHolder) {
              ticketHolder.ticket_holder_name = this.#auth.user()?.name ?? '';
              ticketHolder.ticket_holder_email = this.#auth.user()?.email ?? '';
            }
            this.ticketsIdle(oldTicketId, newTicketId);
          }),
          catchError((error: { message: string; errors: { [key: string]: string[] } }) => {
            this.#snackbar.error('An error occurred assigning the ticket.');
            if (error.errors) {
              this.handleServerErrors(error.errors, index);
            }
            return of(false);
          }),
        )
        .subscribe();
    }
  }

  private handleServerErrors(errors: { [key: string]: string[] }, index: number) {
    const formArray = this.form.get('tickets') as FormArray;
    const ticket = formArray.at(index) as FormGroup;
    this.#errorService.mapServerErrorsToForm(errors, ticket);
    ticket.markAllAsTouched();
  }

  private markTicketAssigned(ticketId: string) {
    const ticket = this.tickets.controls.find((ticket) => ticket.get('id')?.value === ticketId);
    if (ticket) {
      ticket.patchValue({ ticket_holder_name: '', ticket_holder_email: '' });
      ticket.get('isAssigned')?.patchValue(true);
      ticket.markAsUntouched();
      ticket.markAsPristine();
    }
  }

  private clearTicketAssignment(ticketId: string) {
    const ticket = this.tickets.controls.find((ticket) => ticket.get('id')?.value === ticketId);
    if (ticket) {
      ticket.patchValue({ ticket_holder_name: '', ticket_holder_email: '' });
      ticket.get('isAssigned')?.patchValue(false);
      ticket.markAsUntouched();
      ticket.markAsPristine();
    }
  }

  private addTicket(ticket: BookingTicket) {
    this.tickets.push(
      this.#fb.group({
        id: [ticket.id],
        ticket_holder_name: ['', [Validators.required]],
        ticket_holder_email: ['', [Validators.required, Validators.email, emailValidator()]],
        ticketType: [
          `${ticket.ticket_type.ticket_type_option.name}${this.getTicketOptionDescription(ticket.ticket_option)}`,
        ],
        isAssigned: [ticket.isAssigned ?? false],
      }),
    );
  }

  private ticketsBusy(...ticketIds: string[]) {
    this.busyTickets.update((tickets) => [...tickets, ...ticketIds]);
  }

  private ticketsIdle(...ticketIds: string[]) {
    this.busyTickets.update((tickets) => [...tickets.filter((ticket) => !ticketIds.includes(ticket))]);
  }

  private getTicketOptionDescription(ticketOption: string) {
    return ticketOption === TicketOptions.eventOnly
      ? ' (event only)'
      : ticketOption === TicketOptions.gamblingOnly
        ? ' (gambling only)'
        : '';
  }

  getFormControl(groupIndex: number, controlName: string): FormControl {
    const group = this.tickets.at(groupIndex) as FormGroup;
    const ctrl = group.get(controlName) as FormControl;
    return ctrl;
  }
}
