<form [formGroup]="form">
  <div formArrayName="tickets">
    <div class="flex flex-col md:grid md:grid-cols-2 gap-5">
      <div class="pt-5 md:pt-14 md:mb-5">
        <div class="bg-neutral-800 px-3 py-2 md:px-10 md:py-4" role="contentinfo">
          <div class="flex gap-4 items-center text-2xl">
            <i class="fa-regular fa-ticket" aria-hidden="true"></i>
            <h2 class="font-bold">Please assign your tickets</h2>
          </div>
          <p class="pt-2 font-bold text-sm">
            Due to gambling regulations, we now require the name and email of each ticket holder who you are purchasing
            tickets on behalf of.
          </p>
        </div>
      </div>
      <div class="hidden md:block"></div>

      @for (ticket of tickets.controls; track $index) {
        <section
          [formGroupName]="$index"
          class="bg-neutral-800 p-3 md:p-10 relative"
          attr.aria-labelledby="ticket-{{ $index }}-title"
        >
          @if (busyTickets().includes(ticket.get('id')?.value)) {
            <div
              class="absolute top-0 left-0 bg-black bg-opacity-40 flex items-center justify-center w-full h-full z-20"
            >
              <app-spinner [color]="'primary'"></app-spinner>
            </div>
          }

          <h2 id="ticket-{{ $index }}-title" class="text-2xl font-bold pb-3">
            Ticket {{ $index + 1 }} - {{ ticket.get('ticketType')?.value }}
          </h2>

          @if (ticket.get('isAssigned')?.value === true) {
            <app-static-notification type="info">
              <i class="icon fa-regular fa-circle-exclamation text-2xl"></i>
              <p>
                This ticket is assigned to {{ getTicketHolder(ticket.get('id')?.value)?.ticket_holder_name }}
              </p></app-static-notification
            >
          } @else {
            <app-static-notification type="error">
              <i class="icon fa-regular fa-circle-exclamation text-2xl"></i>
              <p>Please assign this ticket to yourself or to an attendee</p>
            </app-static-notification>
          }

          <div class="flex gap-4 flex-wrap pt-5">
            <div class="flex-1 form-field min-w-44">
              <label for="ticket-holder-name-input-{{ $index }}" class="form-label">Attendee Name</label>
              <input
                type="text"
                class="input w-full"
                id="ticket-holder-name-input-{{ $index }}"
                [formControl]="getFormControl($index, 'ticket_holder_name')"
                placeholder="Enter attendee name"
              />

              <app-form-validation-errors
                [control]="getFormControl($index, 'ticket_holder_name')"
                [validationErrors]="{ required: 'Name is required' }"
              ></app-form-validation-errors>
            </div>

            <div class="flex-1 form-field min-w-44">
              <label for="ticket-holder-email-input-{{ $index }}" class="form-label">Attendee Email</label>
              <input
                type="email"
                class="input w-full"
                id="ticket-holder-email-input-{{ $index }}"
                [formControl]="getFormControl($index, 'ticket_holder_email')"
                placeholder="Enter attendee email"
              />

              <app-form-validation-errors
                [control]="getFormControl($index, 'ticket_holder_email')"
                [validationErrors]="{ required: 'Email is required', email: 'Email must be a valid email address' }"
              ></app-form-validation-errors>
            </div>

            <div class="w-full flex flex-col gap-2 pt-2">
              <button
                type="button"
                class="w-full primary-button"
                [disabled]="ticket.invalid || !ticket.dirty"
                (click)="
                  assignTicket(
                    $index,
                    ticket.get('id')?.value,
                    ticket.get('ticket_holder_name')?.value,
                    ticket.get('ticket_holder_email')?.value
                  )
                "
              >
                Assign to attendee
              </button>
              <button
                type="button"
                class="w-full primary-button"
                (click)="assignToMe($index)"
                [disabled]="
                  assigningToMe() || getTicketHolder(ticket.get('id')?.value)?.ticket_holder_email === myEmail()
                "
              >
                Assign to me
              </button>
              <a
                [routerLink]="['/', 'bookings', booking()?.id, 'tickets', ticket.get('id')?.value]"
                class="w-full filled-button tertiary"
                >View Ticket</a
              >
            </div>
          </div>
        </section>
      }
    </div>
  </div>
</form>
