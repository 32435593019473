@if (booking(); as booking) {
<section class="h-full w-full flex flex-col justify-between p-2 md:p-9 bg-neutral-800 rounded-md"
  [attr.aria-label]="booking.id" [class.opacity-50]="pastEvent()">
  <div>
    <span class="text-primary text-xl font-bold">{{ booking.id }}</span>
    <div class="pt-5">
      <app-event-header [id]="booking.id" [name]="booking.event.additional_title || defaultEventTitle"
        [date]="booking.event.date" [venueName]="booking.event.venue_name"
        [address]="booking.event.venue_address"></app-event-header>
    </div>

    <section class="pt-6 grid grid-cols-[auto_1fr_auto] divide-y divide-gray-dark text-lg" aria-label="Ticket Summary">
      @for (ticket of getTickets(booking); track $index) {
      <div class="border-t border-t-gray-dark py-2 pr-4">{{ ticket.name }}:</div>
      <div class="py-2 pr-2">{{ ticket.count }}</div>
      <div class="py-2">{{ ticket.price | currency }}</div>
      }

      <div class="py-2 pr-4 border-t border-t-gray-dark">Booking Fee:</div>
      <div class="py-2">{{ booking.basket.tickets.length }}</div>
      <div class="py-2">{{ booking.basket.booking_fee | currency }}</div>

      <div class="col-span-2 pt-4">Total:</div>
      <div class="pt-4">{{ booking.basket.total | currency }}</div>
    </section>
  </div>

  <div class="pt-6 flex flex-col gap-4">
    @if (!pastEvent()) {
    @if (isAssignmentIncomplete(booking)) {
    <app-static-notification type="error">
      <i class="icon fa-regular fa-circle-exclamation text-2xl"></i>
      <p>Please transfer your tickets to the other attendees. This must be done at least 24hrs before an
        event</p>
    </app-static-notification>
    }

    <a [routerLink]="['/', 'bookings', booking.id]" class="primary-button h-11">Manage Tickets</a>
    }
  </div>
</section>
}