import { ChangeDetectionStrategy, Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { catchError, EMPTY, finalize, from, tap } from 'rxjs';

import { SnackBar } from '@components/snack-bar';
import { SpinnerComponent } from '@components/spinner';
import { StaticNotificationComponent } from '@components/static-notification';
import { BackButtonComponent } from '@components/back-button';

import { EventHeaderComponent } from '../../components';
import { PurchasedTicket, defaultEventTitle } from '../../models';
import { BookingsService } from '../../services';

@Component({
  selector: 'app-ticket',
  standalone: true,
  imports: [BackButtonComponent, RouterLink, SpinnerComponent, EventHeaderComponent, StaticNotificationComponent],
  templateUrl: './ticket.component.html',
  styleUrl: './ticket.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketComponent implements OnInit, OnDestroy {
  #route = inject(ActivatedRoute);
  #bookingService = inject(BookingsService);
  #router = inject(Router);
  #snackBar = inject(SnackBar);
  defaultEventTitle = defaultEventTitle;

  protected ticket = signal<PurchasedTicket | null>(null);
  protected loadingTicket = signal<boolean>(true);
  protected bookingId = signal<string>('');
  protected ticketId = signal<string>('');

  protected fileUrl = signal<string>('');
  protected fileName = computed(() => `ticket-${this.ticket()?.id}.pdf`);

  ngOnInit() {
    this.bookingId.set(this.#route.snapshot.params['bookingId']);
    this.ticketId.set(this.#route.snapshot.params['ticketId']);

    this.#bookingService
      .getTicket(this.bookingId(), this.ticketId())
      .pipe(
        tap((ticket: PurchasedTicket) => this.ticket.set(ticket)),
        catchError(() => {
          this.#snackBar.error('An error occurred retrieving your booking details');
          return from(this.#router.navigate(['/', 'bookings']));
        }),
        finalize(() => this.loadingTicket.set(false)),
      )
      .subscribe();
  }

  protected qrImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = '/assets/images/bongos-logo-pattern.png';
    imgElement.title = 'QR Code Image failed to load';
  }

  downloadTicket() {
    return this.#bookingService
      .downloadTicket(this.bookingId(), this.ticketId())
      .pipe(
        catchError(() => {
          this.#snackBar.error('Ticket download failed');
          return EMPTY;
        }),
      )
      .subscribe((data: Blob) => {
        if (data) {
          const fileUrl = URL.createObjectURL(data);
          const link = document.createElement('a');

          link.href = fileUrl;
          link.download = this.fileName();
          document.body.appendChild(link);
          link.click();
        }
      });
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.fileUrl());
  }
}
